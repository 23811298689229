<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="LBLBASEINFO" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn
                  v-if="editable"
                  label="LBLREMOVE"
                  icon="remove" />
                <c-btn
                  v-if="editable"
                  label="LBLSAVE"
                  icon="save" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-datepicker
                  label="생산월"
                  name="col8"
                  type="month"
                  v-model="data.col8"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  :required="true"
                  :editable="editable"
                  label="배출시설 명"
                  name="col2"
                  v-model="data.col2">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-plant :required="true" type="edit" name="plantCd" v-model="data.plantCd" />
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-table
            ref="table2"
            title="제품별 생산량"
            tableId="processManual"
            :columns="grid2.columns"
            :gridHeight="grid2.height"
            :data="grid2.data"
            :columnSetting="false"
            :isFullScreen="false"
            :usePaging="false"
            :expandAll="true"
          >
          </c-table>
        </div>
      </div>
    </q-form>
  </div>
</template>
<script>
// import selectConfig from '@/js/selectConfig';
// import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'daily-air-report-detail',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {
          reportId: '',
          isFullScreen: false,
        }
      },
    },
    contentHeight: null,
  },
  data() {
    return {
      col0Items: [
        { code: '0005', codeName: '공정 연소시설' },
        { code: '0021', codeName: '발전용 내연기관' },
        { code: '0055', codeName: '일반 보일러시설' },
      ],
      tierItems1: [
        { code: '1', codeName: 'Kg' },
        { code: '2', codeName: 'Ton' },
      ],
      col2Items: [
        { code: '1', codeName: '고체연료연소' },
        { code: '2', codeName: '기체연료연소' },
        { code: '3', codeName: '액체연료연소' },
      ],
      tierItems: [
        { code: '1', codeName: 'Tier 1' },
        { code: '2', codeName: 'Tier 2' },
        { code: '3', codeName: 'Tier 3' },
        { code: '4', codeName: 'Tier 4' },
      ],
      tierItems2: [
        { code: '1', codeName: 'A그룹' },
        { code: '2', codeName: 'B그룹' },
        { code: '3', codeName: 'C그룹' },
      ],
      fuelItems: [
        { code: '1', codeName: '등류' },
        { code: '2', codeName: '경유' },
        { code: '3', codeName: 'LPG' },
      ],
      fuelItems3: [
        { code: '1', codeName: '신설' },
        { code: '2', codeName: '증설' },
        { code: '3', codeName: '폐쇄' },
      ],
      data: {
        plantCd: '1000',
        col1: '1',
        col2: '2',
        col3: 'A-#2호 보일러',
        col4: '1',
        col5: '2',
        col6: '1',
        col7: '2021-11-03',
        deptCd: 'D10005',
      },
      grid2: {
        columns: [
          {
            name: 'col0',
            field: 'col0',
            label: '제품명',
            align: 'center',
            sortable: true,
          },
          {
            name: 'col1',
            field: 'col1',
            label: '제품단위',
            align: 'center',
            sortable: true,
            style: 'width: 150px',
          },
          {
            name: 'col2',
            field: 'col2',
            label: '생산량',
            align: 'center',
            type: 'text',
            style: 'width: 150px',
            sortable: true,
          },
        ],
        data: [],
        height: '300px',
      },
      editable: true,
    };
  },

  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 220);
    },
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.getData();
    },
    getData() {
      this.data = {
        col1: '0005', 
        col2: '공정 연소시설', 
        col02: '1', 
        plantCd: '1000',
        col3: 'Y', 
        col4: '3000', 
        col5: '2', 
        col6: '1200', 
        col7: '2', 
        col8: '2022-11', 
        col9: '10', 
        col10: '300', 
        col11: '0.1', 
        col12: '1', 
        col13: 'Y', 
        col14: null,
        deptCd: 'D10005'
      }
      this.grid2.data = [
        {
          col0: '제품1',
          col1: 'M/T', 
          col2: '135', 
        },
        {
          col0: '제품2',
          col1: 'M/T', 
          col2: '90', 
        },
      ];
    },
  }
};
</script>